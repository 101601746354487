import React from "react";
import { BsChevronRight } from "react-icons/bs";

const SideSliderList = ({ extraClasss, title, para, logo, onPress }) => {
  return (
    <div
      className={` flex justify-between py-2 ${
        extraClasss || ""
      }  px-2 cursor-pointer`}
      onClick={onPress}
    >
      <div className=" flex items-center justify-between w-[80%] ">
        {logo}
        <div className="w-[85%]">
          <div className="text-[13px] sm:text-[15px] py-[3px] sm:py-2 font-semibold">
            {title}
          </div>
          <div className="text-[10px] sm:text-[13px] ">{para} </div>
        </div>
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <BsChevronRight className="sm:text-[16px] text-[12px]" />
      </div>
    </div>
  );
};

export default SideSliderList;
